:root {
  scrollbar-color: #080918 #30324880 !important;
  scrollbar-width: thin !important;
  --modal-editor__indent: 80px;
}

body {
  position: relative;

  &:before {
    content: "";
    display: block;
    width: 100%;
    height: 220px;
    position: absolute;
    top: -40px;
    left: 0;
    filter: blur(40px);
    background: linear-gradient(rgba(135, 152, 106,.45) 0,rgba(233, 245, 219,.35) 90%); // linear-gradient(rgba(238,174,61,.15) 0,rgba(229,191,125,.05) 90%)
    z-index: -1;
  }
}

header {
  font-size: 1.1rem;
  padding: 20px 0;

  a {
    font-weight: 600;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    display: inline-block;
    top: 4px;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      display: block;
      border-bottom: 1px solid;
      position: absolute;
      bottom: 1px;
      right: 0;

      transform: translateX(0);
      transition: transform 0.5s ease;
    }

    &:hover {
      color: $primary;

      &:after {
        transform: translateX(-100%);
      }
    }
  }
}

section.main {
  padding: 4rem 0 6rem;

  .header-lead {
    padding-bottom: 3rem;
  }

  @include media-breakpoint-up(xxl) {
    padding: 7rem 0 12rem;

    .header-lead {
      padding-bottom: 7rem;
    }
  }

  &.bg-light {
    background: rgba(241, 244, 232, 0.035)!important;
  }

  &.bg-dark {
    background: rgba(176, 191, 158, 0.085)!important; // rgba(0,0,0,.35) - #F8FFF0
  }

  &.bg-gradient {
    background: linear-gradient(0deg,rgba(229,191,125,.076) 10%,rgba(20,20,22,.05) 60%) #141416 !important;
  }

  .heading {
    > * {
      line-height: 2.8rem;
      font-size: 2.4rem;
      font-weight: 800;
      letter-spacing: -.018rem;
      text-transform: uppercase;
      margin: 0;

      @include media-breakpoint-down(md) {
        line-height: 2.3rem;
        font-size: 2rem;
      }
    }
  }

  .wrapper-video {
    position: relative;
    top: -70px;

    .video-preview {
      width: 520px;
      height: 520px;
      border-radius: 50%;
      overflow: hidden;
      border: 15px solid rgba(0, 0, 0, 0.15);
      margin: 0 0 0 auto;
      position: relative;
      box-shadow: 0 0 40px 40px rgba(255, 255, 255,.025);
      z-index: 10;

      video {
        width: 100%;
        height: 100%;

        object-fit: cover;
        object-position: center;
      }
    }

    @include media-breakpoint-down(lg) {
      top: 60px;

      .video-preview {
        margin: 0 auto;
      }
    }

    @include media-breakpoint-down(md) {
      .video-preview {
        padding: 0 10px;
        width: 100%;
        height: auto;
      }
    }
  }

  .button-sound {
    top: 0;
    bottom: 0;
    margin: auto;
    text-align: center;
    position: absolute;
    height: 65px;
    z-index: 10;
  }

  .lead {
    color: #1E3F20; // $primary
    font-weight: bold;
  }

  p {
    font-size: 1.15rem;

    a {
      font-weight: 500;
      text-decoration: none;
      position: relative;
      overflow: hidden;
      display: inline-block;
      top: 4px;

      &:after {
        content: "";
        width: 100%;
        height: 2px;
        display: block;
        border-bottom: 1px solid;
        position: absolute;
        bottom: 1px;
        right: 0;

        transform: translateX(0);
        transition: transform 0.5s ease;
      }

      &:hover {
        color: $primary;

        &:after {
          transform: translateX(-100%);
        }
      }
    }
  }

  .button {
    .btn {
      font-weight: 600;
      padding: 1.25rem 1.95rem;
      text-transform: uppercase;
      letter-spacing: 0.025rem;
    }
  }
}

.lead-top {
  color: #87986A!important; // #EEAE3D
  text-transform: uppercase;
}

.card-invest {
  background: #E9F5DB;
  border: 2px solid rgba(30, 63, 32, 0.5);
  border-radius: 10px;
  height: 100%;
  text-decoration: none;
  box-shadow: 3px 6px 10px 4px rgba(227.25, 226.50896739130434, 226.50896739130434, 0.5);

  .card-body {
    padding: 1.5rem 1.2rem;
  }

  .title {
    color: #1E3F20;
  }
}

.card-image {
  border-radius: 10px;
  height: 100%;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;

    -webkit-filter: grayscale(90%);
    filter: grayscale(90%);
    -webkit-transition: .2s ease-in-out;
    transition: .2s ease-in-out;

    &:hover {
      -webkit-filter: grayscale(0);
      filter: grayscale(0);
    }
  }
}

footer {
  font-size: 0.95rem;
  background: #E9F5DB; // rgba(0,0,0,.25)
  color: #1E3F20;
  padding: 45px 0 10px;

  .heading {
    font-size: 1rem;
    font-weight: bold;
    text-transform: uppercase;
    padding-bottom: 1rem;
  }

  .content {
    color: #1E3F20; // rgba(255, 255, 255, 0.75)
  }

  p {
    margin-bottom: 0.35rem;
  }

  a {
    color: #1E3F20;
    font-weight: 500;
    text-decoration: none;
    position: relative;
    overflow: hidden;
    display: inline-block;

    &:after {
      content: "";
      width: 100%;
      height: 2px;
      display: block;
      border-bottom: 1px solid;
      position: absolute;
      bottom: 1px;
      right: 0;

      transform: translateX(0);
      transition: transform 0.5s ease;
    }

    &:hover {
      color: $primary;

      &:after {
        transform: translateX(-100%);
      }
    }
  }

  .copyright {
    color: rgba(255, 255, 255, 0.25);
    font-size: 0.85rem;
    padding-top: 20px;
  }
}

.scroll-down-arrow {
  background-image: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz48IURPQ1RZUEUgc3ZnIFBVQkxJQyAiLS8vVzNDLy9EVEQgU1ZHIDEuMS8vRU4iICJodHRwOi8vd3d3LnczLm9yZy9HcmFwaGljcy9TVkcvMS4xL0RURC9zdmcxMS5kdGQiPjxzdmcgdmVyc2lvbj0iMS4xIiBpZD0iQ2hldnJvbl90aGluX2Rvd24iIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IiB2aWV3Qm94PSIwIDAgMjAgMjAiIGVuYWJsZS1iYWNrZ3JvdW5kPSJuZXcgMCAwIDIwIDIwIiBmaWxsPSJ3aGl0ZSIgeG1sOnNwYWNlPSJwcmVzZXJ2ZSI+PHBhdGggZD0iTTE3LjQxOCw2LjEwOWMwLjI3Mi0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwYzAuMjcsMC4yNjgsMC4yNzEsMC43MDEsMCwwLjk2OWwtNy45MDgsNy44M2MtMC4yNywwLjI2OC0wLjcwNywwLjI2OC0wLjk3OSwwbC03LjkwOC03LjgzYy0wLjI3LTAuMjY4LTAuMjctMC43MDEsMC0wLjk2OWMwLjI3MS0wLjI2OCwwLjcwOS0wLjI2OCwwLjk3OSwwTDEwLDEzLjI1TDE3LjQxOCw2LjEwOXoiLz48L3N2Zz4=);
  background-size: contain;
  background-repeat: no-repeat;
}

.scroll-down-link {
  cursor:pointer;
  height: 60px;
  width: 60px;
  line-height: 60px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 20px auto 0;
  color: #FFF;
  text-align: center;
  font-size: 70px;
  z-index: 100;
  text-decoration: none;
  text-shadow: 0 0 3px rgba(0, 0, 0, 0.4);

  -webkit-animation: fade_move_down 2s ease-in-out infinite;
  -moz-animation:    fade_move_down 2s ease-in-out infinite;
  animation:         fade_move_down 2s ease-in-out infinite;
}

/*animated scroll arrow animation*/
@-webkit-keyframes fade_move_down {
  0%   { -webkit-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -webkit-transform:translate(0,20px); opacity: 0; }
}
@-moz-keyframes fade_move_down {
  0%   { -moz-transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { -moz-transform:translate(0,20px); opacity: 0; }
}
@keyframes fade_move_down {
  0%   { transform:translate(0,-20px); opacity: 0;  }
  50%  { opacity: 1;  }
  100% { transform:translate(0,20px); opacity: 0; }
}