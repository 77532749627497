@-webkit-keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@keyframes notyf-fadeinup {
  0% {
    opacity: 0;
    transform: translateY(25%);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
@-webkit-keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@keyframes notyf-fadeinleft {
  0% {
    opacity: 0;
    transform: translateX(25%);
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}
@-webkit-keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(25%);
  }
}
@keyframes notyf-fadeoutright {
  0% {
    opacity: 1;
    transform: translateX(0);
  }
  to {
    opacity: 0;
    transform: translateX(25%);
  }
}
@-webkit-keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
}
@keyframes notyf-fadeoutdown {
  0% {
    opacity: 1;
    transform: translateY(0);
  }
  to {
    opacity: 0;
    transform: translateY(25%);
  }
}
@-webkit-keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }
  to {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}
@keyframes ripple {
  0% {
    transform: scale(0) translateY(-45%) translateX(13%);
  }
  to {
    transform: scale(1) translateY(-45%) translateX(13%);
  }
}
.notyf {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  color: #fff;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-end;
  pointer-events: none;
  box-sizing: border-box;
  padding: 20px;
}

.notyf__toast--success {
  .notyf__ripple {
    background: rgb(61, 199, 99);
  }
}

.notyf__toast--error {
  .notyf__ripple {
    background: rgb(230, 41, 101);
  }
}

.notyf__toast {
  display: block;
  overflow: hidden;
  pointer-events: auto;
  -webkit-animation: notyf-fadeinup 0.3s ease-in forwards;
  animation: notyf-fadeinup 0.3s ease-in forwards;
  box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.25);
  position: relative;
  padding: 0 15px;
  border-radius: 2px;
  max-width: 300px;
  transform: translateY(25%);
  box-sizing: border-box;
  flex-shrink: 0;
}
.notyf__toast--disappear {
  transform: translateY(0);
  -webkit-animation: notyf-fadeoutdown 0.3s forwards;
  animation: notyf-fadeoutdown 0.3s forwards;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
}
.notyf__toast--disappear .notyf__icon,
.notyf__toast--disappear .notyf__message {
  -webkit-animation: notyf-fadeoutdown 0.3s forwards;
  animation: notyf-fadeoutdown 0.3s forwards;
  opacity: 1;
  transform: translateY(0);
}
.notyf__toast--disappear .notyf__dismiss {
  -webkit-animation: notyf-fadeoutright 0.3s forwards;
  animation: notyf-fadeoutright 0.3s forwards;
  opacity: 1;
  transform: translateX(0);
}
.notyf__toast--disappear .notyf__message {
  -webkit-animation-delay: 0.05s;
  animation-delay: 0.05s;
}
.notyf__toast--upper {
  margin-bottom: 20px;
}
.notyf__toast--lower {
  margin-top: 20px;
}
.notyf__toast--dismissible .notyf__wrapper {
  padding-right: 30px;
}
.notyf__ripple {
  height: 400px;
  width: 400px;
  position: absolute;
  transform-origin: bottom right;
  right: 0;
  top: 0;
  border-radius: 50%;
  transform: scale(0) translateY(-51%) translateX(13%);
  z-index: 5;
  -webkit-animation: ripple 0.4s ease-out forwards;
  animation: ripple 0.4s ease-out forwards;
}
.notyf__wrapper {
  display: flex;
  align-items: center;
  padding-top: 17px;
  padding-bottom: 17px;
  padding-right: 15px;
  border-radius: 3px;
  position: relative;
  z-index: 10;
}
.notyf__dismiss {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  width: 26px;
  margin-right: -15px;
  -webkit-animation: notyf-fadeinleft 0.3s forwards;
  animation: notyf-fadeinleft 0.3s forwards;
  -webkit-animation-delay: 0.35s;
  animation-delay: 0.35s;
  opacity: 0;
}
.notyf__dismiss-btn {
  background-color: rgba(0, 0, 0, 0.25);
  border: none;
  cursor: pointer;
  transition: opacity 0.2s ease, background-color 0.2s ease;
  outline: none;
  opacity: 0.35;
  height: 100%;
  width: 100%;
}
.notyf__dismiss-btn:after,
.notyf__dismiss-btn:before {
  content: "";
  background: #fff;
  height: 12px;
  width: 2px;
  border-radius: 3px;
  position: absolute;
  left: calc(50% - 1px);
  top: calc(50% - 5px);
}
.notyf__dismiss-btn:after {
  transform: rotate(-45deg);
}
.notyf__dismiss-btn:before {
  transform: rotate(45deg);
}
.notyf__dismiss-btn:hover {
  opacity: 0.7;
  background-color: rgba(0, 0, 0, 0.15);
}
.notyf__dismiss-btn:active {
  opacity: 0.8;
}
.notyf__message {
  vertical-align: middle;
  position: relative;
  opacity: 0;
  -webkit-animation: notyf-fadeinup 0.3s forwards;
  animation: notyf-fadeinup 0.3s forwards;
  -webkit-animation-delay: 0.25s;
  animation-delay: 0.25s;
  line-height: 1.5em;
}
@media only screen and (max-width: 480px) {
  .notyf {
    padding: 0;
  }
  .notyf__ripple {
    height: 600px;
    width: 600px;
    -webkit-animation-duration: 0.5s;
    animation-duration: 0.5s;
  }
  .notyf__toast {
    max-width: none;
    border-radius: 0;
    box-shadow: 0 -2px 7px 0 rgba(0, 0, 0, 0.13);
    width: 100%;
  }
  .notyf__dismiss {
    width: 56px;
  }
}
